import React, { FC, useEffect, useState } from "react";
import { Puzzle } from "../models/puzzle";
import classes from "./ClueList.module.scss";
import "./ClueList.module.scss";
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  List,
  ListItem,
  Typography,
} from "@mui/material";
import { Lightbulb } from "@mui/icons-material";
import { Flip, FlipBack, FlipFront } from "../_shared/Flip";

export type ClueListProps = {
  puzzle?: Puzzle;
  tutorialShowing?: boolean;
};

export const ClueList: FC<ClueListProps> = ({ puzzle, tutorialShowing }) => {
  const [showClues, setShowClues] = useState(false);
  useEffect(() => {
    const today = new Date();
    const datestamp = `${today.getFullYear()}-${today.getMonth()}-${today.getDate()}`;
    if (
      !tutorialShowing &&
      window.innerWidth < 600 &&
      localStorage.getItem("tutorial") &&
      !localStorage.getItem(`clues-${datestamp}`)
    ) {
      localStorage.setItem(`clues-${datestamp}`, "true");
      setShowClues(true);
    }
  }, [tutorialShowing]);
  return (
    <>
      <Dialog open={showClues} onClose={() => setShowClues(false)}>
        <DialogTitle align="center">Clues</DialogTitle>
        <DialogContent>
          <List>
            {puzzle?.clues?.map((x) => (
              <ListItem key={x[1].clue}>
                <Typography>
                  <Typography display="inline" fontWeight="bold">
                    #{x[0]} {x[1].direction}
                  </Typography>
                  : {x[1].clue}
                </Typography>
              </ListItem>
            ))}
          </List>
        </DialogContent>
      </Dialog>
      <Button
        color={"primary"}
        sx={{ width: 240 }}
        variant="contained"
        disableElevation
        className={classes.clueButton}
        onClick={() => setShowClues(true)}
        startIcon={<Lightbulb sx={(theme) => ({color: theme.palette.background.default})} />}
      >
        Today's Clues
      </Button>
      <List
        className={classes.clueList}
        style={{
          listStyle: "none",
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
        }}
      >
        {puzzle?.clues?.map((x) => (
          <ListItem key={x[1].clue}>
            <Typography>
              <Typography display="inline" fontWeight="bold">
                #{x[0]} {x[1].direction}
              </Typography>
              : {x[1].clue}
            </Typography>
          </ListItem>
        ))}
      </List>
    </>
  );
};
