import {Direction} from "../models/direction";
import {Grid, Letter} from "../models/grid";
import {ChessPiece} from "./chessPiece";

export class Word {
    constructor(wordString: string, public clue: string,public x : number, public y : number, public direction: Direction) {
        this.word = new Array<Letter>();
        for (let i = 0; i < wordString.length; i++) {
            this.word.push(wordString[i] as Letter);
        }
    }
    word : Array<Letter>;
}
export class Puzzle {
    constructor(public words: Array<Word>, public pieces: ChessPiece[], public initialState: Grid) {
        this.finalState = Puzzle.createFinalState(words, initialState[0].length, initialState.length);
        this.clues = [];
        for (let i = 0; i < words.length; i++) {
            this.clues.push([i+1, words[i]]);
        }
    }
    finalState: Grid;
    clues: [number, Word][];

    static createFinalState(words: Array<Word>, width: number, height: number) {
        const finalState: Grid = new Array<Array<Letter>>(height);
        for (let y = 0; y < height; y++) {
            finalState[y] = new Array<Letter>();
            for (let i = 0; i < width ; i++) {
                finalState[y].push(null);
            }
        }
        for (let y = 0; y < height; y++) {
            for (let x = 0; x < width; x++) {
                for (const word of words) {
                    if( word.direction === Direction.Down && x === word.x && (y >= word.y && y < (word.y + word.word.length))) {
                        finalState[y][x] = word.word[y - word.y];
                    }
                    if( word.direction === Direction.Across && y === word.y && (x >= word.x && x < (word.x + word.word.length))) {
                        finalState[y][x] = word.word[x - word.x];
                    }
                }
            }
        }
        return finalState;
    }
}
