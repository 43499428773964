export enum ChessPiece {
    Pawn = 'Pawn',
    Knight = 'Knight',
    Bishop = 'Bishop',
    Rook = 'Rook',
    Queen = 'Queen',
    King = 'King',
}

export const chessPieceToUnicode = (piece: ChessPiece) => {
    switch (piece) {
        case ChessPiece.Pawn:
            return '♙';
        case ChessPiece.Knight:
            return '♘';
        case ChessPiece.Bishop:
            return '♗';
        case ChessPiece.Rook:
            return '♖';
        case ChessPiece.Queen:
            return '♕';
        case ChessPiece.King:
            return '♔';
    }
}