import React, { FC, useEffect, useState } from "react";
import { DailyStats } from "./dailyStats";
import { Bar, BarChart, ResponsiveContainer, Tooltip, XAxis } from "recharts";
import { Stack, Typography } from "@mui/material";

export type AttemptsProps = {
  stats: DailyStats;
  hard?: boolean;
};

export const Attempts: FC<AttemptsProps> = ({ stats, hard }) => {
  const [data, setData] = useState<any[]>([]);
  useEffect(() => {
    const statsArray = Object.entries(stats);
    const data = [1,2,3,4,5]
      .map((i) => ({
        name: i === 5 ? "5+" : i.toString(),
        count: statsArray.filter(
          ([key, value]) =>
            value[hard ? "hard" : "easy"] &&
            (i === 5
              ? value[hard ? "hard" : "easy"].attempts >= 5
              : value[hard ? "hard" : "easy"].attempts === i)
        ).length,
      }));
    setData(data);
  }, [stats]);
  return (
    <Stack alignItems={"center"} height={'100%'}>
      <Typography fontWeight={'bold'}>Attempts</Typography>
      <ResponsiveContainer width={"100%"} height={"100%"}>
        <BarChart data={data}>
          <Bar dataKey={"count"} name={'Count'} fill={'rgba(10, 193, 157, 1)'} />
          <XAxis dataKey={"name"} />
          <Tooltip />
        </BarChart>
      </ResponsiveContainer>
    </Stack>
  );
};
