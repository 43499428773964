import React, { FC, useEffect, useState } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Hidden,
  Stack,
  Tab,
  Typography,
} from "@mui/material";
import { Share } from "@mui/icons-material";
import { ChessPiece, chessPieceToUnicode } from "../models/chessPiece";
import { useLocation } from "react-router-dom";
import { Attempts } from "./AttemptsChart";
import { DailyStats } from "./dailyStats";
import { CompletionTimeChart } from "./CompletionTimeChart";
import { TabContext, TabList, TabPanel } from "@mui/lab";

export type SuccessProps = {
  open: boolean;
  onClose: (open: boolean) => void;
  puzzleDate: Date;
  pieces?: ChessPiece[];
  stats?: DailyStats;
  hard?: boolean;
  setSnackbar: (message: string) => void;
};

export const Stats: FC<SuccessProps> = ({
  open,
  onClose,
  puzzleDate,
  pieces,
  stats,
  hard,
  setSnackbar,
}) => {
  const location = useLocation();
  const dateStamp = `${puzzleDate.getFullYear()}${
    puzzleDate.getMonth() + 1
  }${puzzleDate.getDate()}`;
  const [timeInSeconds, setTimeInSeconds] = useState<number>();
  const [attempts, setAttempts] = useState<number>();
  const [tab, setTab] = useState<"attempts" | "completionTime">("attempts");
  const createCongratsMessage = () => {
    if (
      !attempts ||
      !stats ||
      !stats[dateStamp] ||
      !stats[dateStamp][hard ? "hard" : "easy"]
    ) {
      return "Stats";
    }
    switch (attempts) {
      case 1:
        return "Incredible!";
      case 2:
        return "Excellent!";
      case 3:
        return "Great job!";
      default:
        return "You did it!";
    }
  };
  useEffect(() => {
    if (stats && stats[dateStamp] && stats[dateStamp][hard ? "hard" : "easy"]) {
      setTimeInSeconds(
        stats[dateStamp][hard ? "hard" : "easy"].completionTimeInSeconds
      );
      setAttempts(stats[dateStamp][hard ? "hard" : "easy"].attempts);
    }
  }, [dateStamp, hard, stats]);
  const timeToComplete =
    timeInSeconds &&
    `${Math.floor(timeInSeconds / 60)}:${(Math.floor(timeInSeconds) % 60)
      .toFixed(0)
      .padStart(2, "0")}`;
  const handleShareClick = () => {
    const puzzleTitle = `Chisel ${puzzleDate.getFullYear()}-${puzzleDate.getMonth()}-${puzzleDate.getDate()}`;
    const isHard = location.pathname.includes("hard");
    const hardText = isHard ? " Hard" : "";
    const attemptsText = `Attempt #${attempts}`;
    const timeText = `Completed in ${timeToComplete}`;
    const piecesText = pieces?.map(chessPieceToUnicode).join("");
    const shareText = `${puzzleTitle}${hardText}\n${attemptsText}\n${timeText}\n${piecesText}\n\nhttps://mjl.to/chisel${
      isHard ? "/hard" : ""
    }`;
    if (navigator.share && navigator.canShare({ text: shareText })) {
      navigator.share({
        text: shareText,
      });
    } else if (navigator.clipboard) {
      navigator.clipboard.writeText(shareText).then(() => {
        setSnackbar("Copied to clipboard!");
      });
    }
  };
  return (
    <Dialog fullWidth maxWidth="sm" open={open} onClose={() => onClose(false)}>
      <DialogTitle align="center">{createCongratsMessage()}</DialogTitle>
      <DialogContent>
        <Stack spacing={2} alignItems="center">
          {timeInSeconds && (
            <>
              <Stack
                sx={{ width: "100%" }}
                direction="row"
                justifyContent="space-between"
                alignItems="space-between"
              >
                <Typography variant="h5">Attempts:</Typography>
                <Typography variant="h5" fontWeight="bold">
                  {attempts}
                </Typography>
              </Stack>
              {timeToComplete && (
                <Stack
                  sx={{ width: "100%" }}
                  direction="row"
                  justifyContent="space-between"
                  alignItems="space-between"
                >
                  <Typography variant={"h5"}>Time to complete:</Typography>
                  <Typography variant="h5" fontWeight="bold">
                    {timeToComplete}
                  </Typography>
                </Stack>
              )}
            </>
          )}
          {stats && (
            <>
              <Hidden smDown>
                <Grid container minHeight={320}>
                  <Grid item xs={12} sm={6}>
                    <Attempts stats={stats} hard={hard} />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <CompletionTimeChart stats={stats} hard={hard} />
                  </Grid>
                </Grid>
              </Hidden>
              <Hidden smUp>
                <TabContext value={tab}>
                  <TabList onChange={(e, v) => setTab(v)}>
                    <Tab label="Attempts" value={"attempts"} />
                    <Tab label="Completion Time" value={"completionTime"} />
                  </TabList>
                  <TabPanel value={"attempts"} sx={{ width: "100%" }}>
                    <Box height={320} width={"100%"}>
                      <Attempts stats={stats} hard={hard} />
                    </Box>
                  </TabPanel>
                  <TabPanel value={"completionTime"} sx={{ width: "100%" }}>
                    <Box height={320} width={"100%"}>
                      <CompletionTimeChart stats={stats} hard={hard} />
                    </Box>
                  </TabPanel>
                </TabContext>
              </Hidden>
            </>
          )}
        </Stack>
      </DialogContent>
      <DialogActions>
        <Stack sx={{ width: "100%" }} alignItems="center" justifyItems="center">
          <Button
            variant={"contained"}
            disableElevation
            startIcon={<Share fontVariant="h5" />}
            onClick={handleShareClick}
          >
            <Typography variant={"h5"}>Share</Typography>
          </Button>
        </Stack>
      </DialogActions>
    </Dialog>
  );
};
