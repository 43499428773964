import * as React from "react";
import {FC, useEffect} from "react";
import {ChessPiece} from "../models/chessPiece";
import {Grid, Letter} from "../models/grid";
import "./InteractiveGrid.scss";
import {DragPreviewImage, useDrag, useDragDropManager, useDrop} from "react-dnd";
import clsx from "clsx";
import {isPieceMoveValid} from "../services/moveService";
import {DndProvider} from "react-dnd-multi-backend";
import {HTML5toTouch} from "rdndmb-html5-to-touch";
import {Puzzle} from "../models/puzzle";

export type DraggableLetterProps = {
    letter: Letter;
    availableOption?: boolean;
    pos: [number, number];
};

export const DraggableLetter: FC<DraggableLetterProps> = ({
                                                              letter,
                                                              pos,
                                                              availableOption,
                                                          }) => {
    const [{id, isDragging}, drag, preview] = useDrag({
        type: "ChessPiece",
        item: pos,
        collect: (monitor) => ({
            id: monitor.getHandlerId(),
            isDragging: monitor.isDragging(),
        }),
    });
    if (letter) {
        return (
            <>
                <DragPreviewImage connect={preview} src={letter ?? ""}/>
                <div
                    className={clsx("letter", {
                        "letter--dragging": isDragging,
                        "letter--option": availableOption,
                    })}
                    ref={drag}
                >
                    {letter}
                </div>
            </>
        );
    }
    return (
        <div
            className={clsx("letter", "letter-blank", {
                "letter--option": availableOption,
            })}
        >
            {letter}
        </div>
    );
};

export type InteractiveCellProps = {
    state: Grid;
    gridRowIndex: number;
    gridColIndex: number;
    gridCell: Letter;
    currentPiece: ChessPiece;
    // Utilized to force re-rendering as necessary
    moveNumber: number;
    onDrop: (fromPos: [number, number], toPos: [number, number]) => void;
    clueNumber?: string;
    error?: boolean;
};
export const InteractiveCell: FC<InteractiveCellProps> = ({
                                                              state,
                                                              currentPiece,
                                                              clueNumber,
                                                              gridRowIndex,
                                                              gridColIndex,
                                                              moveNumber,
                                                              gridCell,
                                                              onDrop,
    error
                                                          }) => {
    const [{isOver, canDrop}, drop] = useDrop(
        () => ({
            accept: "ChessPiece",
            canDrop: (item: [number, number]) =>
                isPieceMoveValid(
                    state,
                    currentPiece,
                    item[0],
                    item[1],
                    gridColIndex,
                    gridRowIndex
                ),
            drop: (item: [number, number]) =>
                onDrop(item, [gridColIndex, gridRowIndex]),
            collect: (monitor) => ({
                isOver: monitor.isOver(),
                canDrop: monitor.canDrop(),
            }),
        }),
        [gridRowIndex, gridColIndex, currentPiece, moveNumber]
    );
    return (
        <div
            ref={drop}
            className={clsx("chessBoard-space", {
                "chessBoard-space--option": canDrop,
                "chessBoard-space--target": canDrop && isOver,
            })}
        >
            <div className={clsx("chessBoard-space-inner", {
                "chessBoard-space-inner--error": error,
            })
            }>
            {clueNumber && <div className="chessBoard-space--clue">{clueNumber}</div>}
            <DraggableLetter letter={gridCell} pos={[gridColIndex, gridRowIndex]}/>
            </div>
        </div>
    );
};

export type InteractiveGridProps = {
    state: Grid;
    onChange: (newState: Grid) => void;
    pieces: ChessPiece[];
    currentPieceIndex: number;
    puzzle: Puzzle;
};

export const InteractiveGrid: FC<InteractiveGridProps> = ({
                                                              state,
                                                              puzzle,
                                                              onChange,
                                                              pieces,
                                                              currentPieceIndex,
                                                          }) => {
    const handleChange = (fromPos: [number, number], toPos: [number, number]) => {
        const newState = state.map((row, rowIndex) =>
            row.map((cell, colIndex) => {
                if (rowIndex === fromPos[1] && colIndex === fromPos[0]) {
                    return null;
                }
                if (rowIndex === toPos[1] && colIndex === toPos[0]) {
                    return state[fromPos[1]][fromPos[0]];
                }
                return cell;
            })
        );
        onChange(Array.from(newState) as Grid);
    };
    return (
        <DndProvider options={HTML5toTouch}>
            <div className={clsx('chessBoard', `chessBoard-${puzzle.initialState.length}`)}>
                {state.map((gridRow, y) => (
                    <div key={y} className={"chessBoard-row"}>
                        {gridRow.map((gridCell, x) => {
                            let clueNumber: number[] = [];
                            for (let i = 0; i < puzzle.clues.length; i++) {
                                const [num, word] = puzzle.clues[i];
                                if (word.x === x && word.y === y) {
                                    clueNumber.push(num);
                                }
                            }
                            return (
                                <InteractiveCell
                                    key={x}
                                    state={state}
                                    gridRowIndex={y}
                                    gridColIndex={x}
                                    currentPiece={pieces[currentPieceIndex]}
                                    moveNumber={currentPieceIndex}
                                    gridCell={gridCell}
                                    clueNumber={clueNumber.join(', ')}
                                    onDrop={handleChange}
                                    error={currentPieceIndex === pieces.length && state[y][x] !== puzzle.finalState[y][x]}
                                />
                            );
                        })}
                    </div>
                ))}
            </div>
        </DndProvider>
    );
};
