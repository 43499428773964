import {ChessPiece} from "../models/chessPiece";
import {Grid} from "../models/grid";

export const isReversePawnMove = (boardState: Grid, fromCol: number, fromRow: number, toCol: number, toRow: number) => {
    return  (toRow - fromRow === 1 && Math.abs(fromCol - toCol) === 1 && boardState[toRow][toCol] === null) // Diagonal capture
        || (toRow - fromRow === 1 && fromCol === toCol && boardState[toRow][toCol] === null); // Forward one
}

export const isPawnMove = (boardState: Grid, fromCol: number, fromRow: number, toCol: number, toRow: number) => {
    return (fromRow - toRow === 1 && Math.abs(fromCol - toCol) === 1 && boardState[toRow][toCol] !== null) // Diagonal capture
        || (fromRow - toRow === 1 && fromCol === toCol && boardState[toRow][toCol] === null); // Forward one
}
export const isKnightMove = (boardState: Grid, fromCol: number, fromRow: number, toCol: number, toRow: number) => {
    return Math.abs(fromRow - toRow) === 2 && Math.abs(fromCol - toCol) === 1
        || Math.abs(fromRow - toRow) === 1 && Math.abs(fromCol - toCol) === 2;
}

export const isBishopMove = (boardState: Grid, fromCol: number, fromRow: number, toCol: number, toRow: number) => {
    const isDiagonal = Math.abs(fromRow - toRow) === Math.abs(fromCol - toCol);
    const xSign = Math.sign(toCol - fromCol);
    const ySign = Math.sign(toRow - fromRow);
    for (
        let i = fromCol + xSign, j = fromRow + ySign;
        (Math.abs(i - toCol) > 0 && Math.abs(j - toRow) > 0) && i >= 0 && j >= 0 && i < boardState.length && j < boardState.length;
        i += xSign, j += ySign) {
        if (boardState[j][i] !== null) return false;
    }
    return isDiagonal;
}
export const isRookMove = (boardState: Grid, fromCol: number, fromRow: number, toCol: number, toRow: number) => {
    const isHorizontal = fromRow === toRow || fromCol === toCol;
    const xSign = Math.sign(toCol - fromCol);
    const ySign = Math.sign(toRow - fromRow);
    for (let i = fromCol + xSign, j = fromRow + ySign;
         (Math.abs(i - toCol) > 0 || Math.abs(j - toRow) > 0) && i >= 0 && j >= 0 && i < boardState.length && j < boardState.length;
         i += xSign, j += ySign) {
        if (boardState[j][i] !== null) return false;
    }
    return isHorizontal;
}

export const isQueenMove = (boardState: Grid, fromCol: number, fromRow: number, toCol: number, toRow: number) => {
    return isBishopMove(boardState, fromCol, fromRow, toCol, toRow) || isRookMove(boardState, fromCol, fromRow, toCol, toRow);
}

export const isKingMove = (boardState: Grid, fromCol: number, fromRow: number, toCol: number, toRow: number) => {
    return Math.abs(fromRow - toRow) <= 1 && Math.abs(fromCol - toCol) <= 1;
}

export const isPieceMoveValid = (boardState: Grid, piece: ChessPiece, fromCol: number, fromRow: number, toCol: number, toRow: number) => {
    if (fromRow === toRow && fromCol === toCol) return false; // Pieces can't move to their own squares
    switch (piece) {
        case ChessPiece.Pawn:
            return isPawnMove(boardState, fromCol, fromRow, toCol, toRow);
        case ChessPiece.Knight:
            return isKnightMove(boardState, fromCol, fromRow, toCol, toRow);
        case ChessPiece.Bishop:
            return isBishopMove(boardState, fromCol, fromRow, toCol, toRow);
        case ChessPiece.Rook:
            return isRookMove(boardState, fromCol, fromRow, toCol, toRow);
        case ChessPiece.Queen:
            return isQueenMove(boardState, fromCol, fromRow, toCol, toRow);
        case ChessPiece.King:
            return isKingMove(boardState, fromCol, fromRow, toCol, toRow);
        default:
            return false;
    }
}