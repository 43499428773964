import React, { FC, useEffect } from "react";
import { useNavigate } from "react-router-dom";

export const PWAStarter: FC = () => {
  const navigate = useNavigate();

  useEffect(() => {
    localStorage.getItem("difficulty") === "hard"
      ? navigate("/hard", { replace: true })
      : navigate("/", { replace: true });
  }, []);
  return <></>;
};
