import classes from "./parallax.module.scss";
import { FC, PropsWithChildren, ReactNode, useEffect, useState } from "react";
import { ParallaxLayerIndex } from "./ParallaxTypes";

export const Parallax: FC<PropsWithChildren<{}>> = (props) => {
  return (
    <div id="parallax" className={classes.parallax}>
      {props.children}
    </div>
  );
};

export type ParallaxLayerProps = {
  layer: ParallaxLayerIndex;
  children?: ReactNode;
  targetId?: string;
};

export const ParallaxLayer = ({
  layer,
  children,
  targetId,
}: ParallaxLayerProps) => {
  const [targetOffsetY, setTargetOffsetY] = useState(0);
  const [totalImageCount, setTotalImageCount] = useState(0);
  const [loadedImageCount, setLoadedImageCount] = useState(0);

  const updateTargetOffset = () => {
    const parallax = document.querySelector("#parallax");
    const target = document.querySelector(`#${targetId}`);

    if (parallax && target) {
      const { top } = target.getBoundingClientRect();
      setTargetOffsetY(top + parallax.scrollTop);
    }
  };

  const incrementLoadedImageCount = () => {
    setLoadedImageCount((prev) => prev + 1);
  };

  const addImageLoadListeners = () => {
    setTotalImageCount(document.images.length);
    //
    // [].forEach.call(document.images, (img) => {
    //     if (img.complete) {
    //         incrementLoadedImageCount();
    //         img.removeEventListener('load', incrementLoadedImageCount);
    //     } else {
    //         img.addEventListener('load', incrementLoadedImageCount, false);
    //     }
    // });
  };

  useEffect(() => {
    if (totalImageCount > 0 && totalImageCount === loadedImageCount) {
      updateTargetOffset();
    }
  }, [totalImageCount, loadedImageCount]);

  useEffect(() => {
    if (!targetId) return;
    updateTargetOffset();
    // Listen for image loading so that we can update the target offset once all images have loaded
    addImageLoadListeners();
    window.addEventListener("resize", updateTargetOffset);
    return () => window.removeEventListener("resize", updateTargetOffset);
  }, [targetId]);

  return (
    <div
      className={classes["parallax-layer-" + layer]}
      style={{ top: targetOffsetY }}
    >
      {children}
    </div>
  );
};
