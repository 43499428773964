import {createTheme} from "@mui/material";
import shadows from "@mui/material/styles/shadows";

export const theme = createTheme({
    palette: {
        primary: {
            main: "rgba(10, 193, 157, 1)",
        },
        error: {
            main: "#ff4d40",
        },
        background: {
            paper: "rgb(237, 235, 232)",
        },
    },
    typography: {
        h1: {
            fontSize: "2.5rem",
            fontFamily: "Space Mono, Roboto, sans-serif",
        },
        h2: {
            fontFamily: "Space Mono, Roboto, sans-serif",
        },
        body1: {
            fontSize: "1.2rem",
        },
        // fontFamily: "Space Mono, Roboto, sans-serif",
        fontFamily: "Work Sans, Roboto, sans-serif",
    },
    components: {
        MuiDialogTitle: {
            styleOverrides: {
                root: {
                    fontSize: '1.5rem'
                }
            }
        }
    }
});
