import React, {FC, useEffect, useState} from "react";
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Stack, Typography} from "@mui/material";
import step0Before from './step0-before.png';
import step0After from './step0-after.png';
import step2 from './step2.png';
import step3 from './step3.png';

export type TutorialProps = {
    open: boolean;
    onClose: () => void;
}

export const Tutorial: FC<TutorialProps> = ({open, onClose}) => {
    const [tutorialStep, setTutorialStep] = useState(0);

    const handleTutorialClose = () => {
        localStorage.setItem('tutorial', 'true');
        onClose();
    }

    useEffect(() => {
        if (!open)
            setTimeout(() => setTutorialStep(0), 500);
        else setTutorialStep(0);
    }, [open]);

    const handleNextStep = () => {
        setTutorialStep(tutorialStep + 1);
    }
    const handlePreviousStep = () => {
        setTutorialStep(tutorialStep - 1);
    }
    return (

        <Dialog fullWidth maxWidth='xs' open={open} onClose={handleTutorialClose}>
            <DialogTitle align='center'>Chisel, a Chess Puzzle</DialogTitle>
            <DialogContent sx={{height: '480px'}}>
                {tutorialStep === 0 && (
                    <Stack direction={'column'} spacing={2}>
                        <Typography>Chisel is a combination of a chess puzzle and a crossword.</Typography>
                        <Typography>The goal of the game is to end up with a grid of letters forming words as given by
                            the hints above, and <Typography display={'inline'} fontWeight='bold'
                                                             fontStyle='italic'>only</Typography> those
                            letters.</Typography>
                        <Stack direction={'row'} justifyContent={'center'} alignItems={'center'} spacing={2}>
                            <div>
                                <img src={step0Before} height={'320'} alt={'Before'}/>
                                <Typography align='center' fontWeight='bold'>Before</Typography>
                            </div>
                        </Stack>
                    </Stack>)}
                {tutorialStep === 1 && (
                    <Stack direction={'column'} spacing={2}>
                        <Typography>Chisel is a combination of a chess puzzle and a crossword.</Typography>
                        <Typography>The goal of the game is to end up with a grid of letters forming words as given by
                            the hints above, and <Typography display={'inline'} fontWeight='bold'
                                                             fontStyle='italic'>only</Typography> those
                            letters.</Typography>
                        <Stack direction={'row'} justifyContent={'center'} alignItems={'center'} spacing={2}>
                            <div>
                                <img src={step0After} height={'320'} alt={'Before'}/>
                                <Typography align='center' fontWeight='bold'>After</Typography>
                            </div>
                        </Stack>
                    </Stack>)}
                {tutorialStep === 2 && (
                    <Stack direction={'column'} spacing={2}>
                        <Typography>It's played simply by making a few moves.</Typography>
                        <Typography>Each move consists of taking a piece and moving it via a single chess move based on
                            the chess piece highlighted below.</Typography>
                        <Stack paddingTop={12} direction={'row'} justifyContent={'center'} alignItems={'center'}
                               spacing={2}>
                            <img src={step2} width='320' alt={'Before'}/>
                        </Stack>
                        <Typography sx={{paddingTop: 12}}>Each move will happen once, then the chess piece will
                            advance.</Typography>
                    </Stack>)}
                {tutorialStep === 3 && (
                    <Stack direction={'column'} spacing={2}>
                        <Typography>Available moves will be highlighted in green when dragging a piece</Typography>
                        <Stack paddingTop={12} direction={'row'} justifyContent={'center'} alignItems={'center'}
                               spacing={2}>
                            <img src={step3} width='320' alt={'Before'}/>
                        </Stack>
                    </Stack>)}
                {tutorialStep === 4 && (
                    <Stack direction={'column'} spacing={2}>
                        <Typography>Drag pieces around and play Chisel!</Typography>
                        <Typography>New boards are available daily.</Typography>
                    </Stack>)}
            </DialogContent>
            <DialogActions>
                <Stack width={'100%'} direction='row' justifyContent='space-around'>
                    {<Button disabled={tutorialStep === 0} onClick={handlePreviousStep}>Previous</Button>}
                    <Button variant={tutorialStep === 4 ? 'contained' : undefined} disableElevation
                            onClick={handleTutorialClose}>Play Now</Button>
                    {tutorialStep !== 4 && (
                        <Button variant='contained' disableElevation onClick={handleNextStep}>Next</Button>)}
                </Stack>
            </DialogActions>
        </Dialog>
    );
}
