import { Puzzle, Word } from '../models/puzzle';
import { ChessPiece } from '../models/chessPiece';
import { Direction } from '../models/direction';

export const tutorialOne = new Puzzle(
    [
        new Word("this", "'this'", 2, 0, Direction.Down),
        new Word("spin", "'spin'", 0, 2, Direction.Across),
        new Word("hot", "'hot'", 0, 0, Direction.Across),
    ],
    [ChessPiece.Pawn],
    [
        ['i', 'o', 't', null],
        [null,'h', 'h', null],
        ['s','p', 'i', 'n'],
        [null, null, 's', null]
    ]);

export const tutorialTwo = new Puzzle(
    [
        new Word("open", "Not closed", 0, 0, Direction.Across),
        new Word("plan", "A series of steps", 1, 0, Direction.Down),
        new Word("save", "To ____ for later", 0, 2, Direction.Across),
    ],
    [ChessPiece.Knight, ChessPiece.King],
    [
        ['o', 'p', 'e', 'n'],
        [null,'l', null, null],
        ['s', null, 'l', 'e'],
        [null, 'n', 'v', 'a']
    ]);

export const tutorialThree = new Puzzle(
    [
        new Word("you", "Second-person pronoun", 1, 1, Direction.Down),
        new Word("got", "Have acquired in the past", 0, 2, Direction.Across),
        new Word("it", "A Stephen King horror novel", 2, 1, Direction.Down),
    ],
    [ChessPiece.Rook, ChessPiece.Bishop, ChessPiece.Queen],
    [
        [null, null, null, null],
        ['o','y', 'i', 't'],
        ['g','s', 'e', null],
        [null, 'b', null, 'u']
    ]);
