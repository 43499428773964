import React, { PropsWithChildren, ReactElement, ReactNode } from "react";
import classes from "./Flip.module.scss";
import clsx from "clsx";

type FlipFrontProps = PropsWithChildren<{}>;

const FlipFront: React.FC<FlipFrontProps> = ({ children }) => <>{children}</>;
FlipFront.displayName = "Front";

type FlipBackProps = PropsWithChildren<{}>;

const FlipBack: React.FC<FlipBackProps> = ({ children }) => <>{children}</>;
FlipBack.displayName = "Back";

interface FlipProps {
  flipped: boolean;
  children: [ReactElement<FlipFrontProps>, ReactElement<FlipBackProps>];
}

const Flip: React.FC<FlipProps> = ({ flipped, children }) => {
  const [front, back] = children;

  return (
    <div
      className={clsx(classes.flipContainer, { [classes.flipped]: flipped })}
    >
      <div className={classes.flipper}>
        <div className={classes.front}>{front}</div>
        <div className={classes.back}>{back}</div>
      </div>
    </div>
  );
};

export { Flip, FlipFront, FlipBack };
