import {Puzzle, Word} from "../models/puzzle";
import {Direction} from "../models/direction";
import {ChessPiece} from "../models/chessPiece";

export const puzzleOne = new Puzzle(
    [
        new Word("hop", "To jump slightly", 0, 0, Direction.Across),
        new Word("lich", "An ancient undead", 0, 2, Direction.Across),
        new Word("peck", "A quarter of a bushel", 2, 0, Direction.Down)
    ],
    [ChessPiece.Pawn, ChessPiece.Queen, ChessPiece.Knight, ChessPiece.King],
    [
        ['j', 'o', 'i', 'h'],
        ['c', 'h', 'e', 'p'],
        ['l', 'i', null, 'r'],
        [null, null, 'k', null]
    ]);
