import React, {FC, useEffect} from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {createBrowserRouter, Route, RouterProvider} from "react-router-dom";
import {PWAStarter} from "./PWAStart";

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
const router = createBrowserRouter([
    {
        path: '/:puzzleId?',
        element: <App />
    },
    {
        path: '/pwa-start',
        element: <PWAStarter />
    },
    {
        path: '/hard/:puzzleId?',
        element: <App hard />
    },
    {
        path: '/tutorial/:puzzleId?',
        element: <App tutorial />
    }
]);
root.render(
  <React.StrictMode>
      <RouterProvider router={router}/>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
