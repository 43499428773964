import React, { FC, useEffect, useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Stack,
  Switch,
} from "@mui/material";
import {useLocation, useNavigate, useNavigation} from "react-router-dom";

export type SettingsProps = {
  open: boolean;
  onClose: () => void;
  kioskMode: boolean;
  setKioskMode: (kioskMode: boolean) => void;
};

export const SettingsMenu: FC<SettingsProps> = ({ open, onClose, kioskMode, setKioskMode}) => {
  const location = useLocation();
  const navigation = useNavigate();
  const [hard, setHard] = useState(false);
  const [adminCounter, setAdminCounter] = useState(0);
  useEffect(() => {
    setHard(location.pathname.includes("hard"));
  }, [location]);

  const handleTitleClick = () => {
    setAdminCounter(adminCounter + 1);
  }
  const handleHardChange = (checked: boolean) => {
    if (checked) {
      navigation("/hard", { replace: true });
      localStorage.setItem("difficulty", "hard");
    } else {
      navigation("/", { replace: true });
      localStorage.setItem("difficulty", "normal");
    }
  };
  return (
    <Dialog fullWidth maxWidth="xs" open={open} onClose={onClose}>
      <DialogTitle align="center" onClick={handleTitleClick}>Settings</DialogTitle>
      <DialogContent>
        <Stack spacing={2}>
          {/*<FormControlLabel*/}
          {/*  sx={{*/}
          {/*    justifyContent: "space-between",*/}
          {/*    marginLeft: 0,*/}
          {/*    marginRight: 0,*/}
          {/*  }}*/}
          {/*  labelPlacement="start"*/}
          {/*  control={<Switch />}*/}
          {/*  label="Dark Theme"*/}
          {/*/>*/}
          <FormControlLabel
            sx={{
              justifyContent: "space-between",
              marginLeft: 0,
              marginRight: 0,
            }}
            labelPlacement="start"
            control={
              <Switch
                checked={hard}
                onChange={(_, checked) => handleHardChange(checked)}
              />
            }
            label="Hard Mode"
          />
          {
            adminCounter > 10 && (
                <>
                  <FormControlLabel
                      sx={{
                        justifyContent: "space-between",
                        marginLeft: 0,
                        marginRight: 0,
                      }}
                      labelPlacement="start"
                      control={
                        <Switch
                            checked={kioskMode}
                            onChange={(_, checked) => setKioskMode(checked)}
                        />
                      }
                      label="Kiosk Mode"
                  />
                </>
              )

          }
        </Stack>
      </DialogContent>
    </Dialog>
  );
};
