import {Puzzle, Word} from "../models/puzzle";
import {Direction} from "../models/direction";
import {ChessPiece} from "../models/chessPiece";

export const puzzleTwo = new Puzzle(
    [
        new Word("blue", "Color associated with feeling down", 1, 0, Direction.Down),
        new Word("ally", "A friend", 0, 1, Direction.Across),
        new Word("pen", "A writing instrument", 0, 3, Direction.Across)
    ],
    [ChessPiece.Pawn, ChessPiece.Rook, ChessPiece.Pawn, ChessPiece.Bishop],
    [
        [null, 'b', null, null],
        ['a', 'i', 'g', 'o'],
        ['p', 'u', 'y', 'l'],
        ['f', 'e', 'n', 'l']
    ]);
